import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import '../styles/global-version.scss'
import client from '../api/client'
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import { Row, Col, Button, Form, Input, Select, Switch, notification, Spin } from 'antd'
import KioskInfoModal from '../components/kiosk/info-modal'
import Autocomplete from 'react-google-autocomplete'
const privacyPolicyTextStyle = {
  color: '#11427F',
  fontWeight: 700,
  fontSize: 15,
}

const RegisterPage = () => {
  const [registerFormRef] = Form.useForm()
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [privacyPolicyModalVisible, setPrivacyPolicyModalVisible] = useState(false)
  const [country, setCountry] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const [provinceId, setProvinceId] = useState(null)
  const fetchProvinceList = useCallback(() => {
    client
      .get('/api/lookup/provinces')
      .then(async (res) => {
        setProvinceList(
          res.data.map((province) => ({
            value: province.id,
            label: province.name,
          })),
        )
      })
      .catch((err) => console.error({ err }))
  }, [])

  const fetchDistrictList = useCallback((provinceId) => {
    const endpoint = `/api/lookup/provinces/${provinceId}/districts`
    client
      .get(endpoint)
      .then(async (res) => {
        setDistrictList(
          res.data.map((province) => ({
            value: province.id,
            label: province.name,
          })),
        )
      })
      .catch((err) => console.error({ err }))
  }, [])

  const fetchSubDistrictList = useCallback(
    (districtId) => {
      const endpoint = `/api/lookup/provinces/${provinceId}/districts/${districtId}/subdistricts`
      client
        .get(endpoint)
        .then(async (res) => {
          setSubDistrictList(
            res.data.map((province) => ({
              value: province.id,
              label: province.name,
            })),
          )
        })
        .catch((err) => console.error({ err }))
    },
    [provinceId],
  )

  useEffect(() => {
    registerFormRef.resetFields()
    if (country === 'MN') {
      fetchProvinceList()
    }
  }, [country, fetchProvinceList, registerFormRef])

  const formatPhone = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)
    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)
    const first4 = cleaned.substring(0, 4)
    const last4 = cleaned.substring(4, 8)
    let ret = ''
    if (country === 'MN') {
      if (cleaned.length > 4) ret = `${first4} ${last4}`
      else if (cleaned.length > 0) ret = `${first4}`
    } else {
      if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
      else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
      else if (cleaned.length > 0) ret = `(${zip}`
    }
    registerFormRef.setFieldsValue({ phoneNumber: ret })
  }

  const registerUser = (values) => {
    setLoading(true)
    let endpoint = ''
    let data = {}
    if (country === 'US') {
      endpoint = '/api/accounts/registerinusa'
      data = { ...values }
      data.hasAgreed = privacyPolicyAccepted
    } else {
      endpoint = '/api/accounts/registerinmgl'
      data = {
        phoneNumber: values.phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        hasAgreed: privacyPolicyAccepted,
        address: {
          provinceId: values.provinceId,
          districtId: values.districtId,
          subdistrictId: values.subdistrictId,
          fullAddress: values.fullAddress,
        },
      }
    }
    client
      .post(endpoint, data)
      .then(async (res) => {
        notification.success({
          message: 'Амжилттай бүртгэгдлээ!',
        })
        setTimeout(() => {
          navigate('/login')
          setLoading(false)
        }, 2000)
      })
      .catch((err) => {
        if (err.response.status === 400) notification.warning({ message: err.response.data })
        console.error({ err })
        setLoading(false)
      })
  }

  return (
    <Spin size="large" spinning={loading}>
      <Helmet title="Бүртгэх - SB Express" defer={false} />
      <div style={{ padding: '20px', maxWidth: 1366, margin: '0 auto' }} className="d-flex flex-column h-100">
        <Row>
          <Col style={{ position: 'relative', width: '100%' }}>
            <Button type="link" className="sb-arrow-back" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" style={{ margin: '30px 0' }}>
          <div style={{ maxWidth: '768px' }}>
            <div style={{ fontSize: '48px', paddingBottom: '40px' }} className="text-center">
              Шинээр бүртгүүлэх
            </div>
            <span
              style={{
                fontSize: '18px',
                fontWeight: '400',
                fontStyle: 'italic',
                marginBottom: '20px',
                display: 'block',
              }}
            >
              Та өөрийн мэдээллээ бүртгүүлснээр илгээмж явуулах болон хүлээн авах бүх хаяг мэдээллээ өөрийн аккаунт-даа хадгалах боломжтой болно.
            </span>
            <Form name="web_register" form={registerFormRef} onFinish={registerUser} autoComplete="off" layout="vertical">
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Form.Item
                    className="mb-3"
                    name="country"
                    label="Оршин суугаа улс"
                    rules={[
                      {
                        required: true,
                        message: 'Оршин суугаа улсаа оруулна уу!',
                      },
                    ]}
                  >
                    <Select
                      className="sb-select sb-shadow"
                      popupClassName="sb-select-dropdown"
                      placeholder="Оршин суугаа улс"
                      onChange={async (e) => {
                        await setCountry(e)
                        registerFormRef.setFieldsValue({
                          country: e,
                        })
                      }}
                      suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    >
                      <Select.Option value="US">Америк</Select.Option>
                      <Select.Option value="MN">Монгол</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {country === 'US' ? (
                  <>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="firstName"
                        label="Нэр"
                        rules={[
                          {
                            required: true,
                            message: 'Нэрээ оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Нэр*" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="lastName"
                        label="Овог"
                        rules={[
                          {
                            required: true,
                            message: 'Овог нэрээ оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Овог*" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="phoneNumber"
                        label="Утасны дугаар"
                        rules={[
                          {
                            required: true,
                            message: 'Утасны дугаараа оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Утас*" onChange={(e) => formatPhone(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="email"
                        label="И-мэйл"
                        rules={[
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="И-мэйл" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        className="mb-3"
                        name="address"
                        label="Гэрийн хаяг"
                        rules={[
                          {
                            required: true,
                            message: 'Гэрийн хаягаа оруулна уу!',
                          },
                        ]}
                      >
                        <Autocomplete
                          className="ant-input sb-shadow google-map-input"
                          apiKey={'AIzaSyC8meMe-1DKxh70DZotYw6z-CVj4dSpnbc'}
                          onPlaceSelected={async (place) => {
                            registerFormRef.setFieldsValue({
                              address: place.formatted_address,
                            })
                          }}
                          options={{
                            types: ['address'],
                            componentRestrictions: { country: 'US' },
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Switch
                        checked={privacyPolicyAccepted}
                        defaultChecked={true}
                        onChange={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)}
                      />
                      <Button type="link" style={privacyPolicyTextStyle} onClick={() => setPrivacyPolicyModalVisible(true)}>
                        Би үйлчилгээний нөхцлийг зөвшөөрч байна
                      </Button>
                    </Col>
                  </>
                ) : country === 'MN' ? (
                  <>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="firstName"
                        label="Нэр"
                        rules={[
                          {
                            required: true,
                            message: 'Нэрээ оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Нэр*" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="lastName"
                        label="Овог"
                        rules={[
                          {
                            required: true,
                            message: 'Овог нэрээ оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Овог*" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="phoneNumber"
                        label="Утасны дугаар"
                        rules={[
                          {
                            required: true,
                            message: 'Утасны дугаараа оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Утас*" onChange={(e) => formatPhone(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="email"
                        label="И-мэйл"
                        rules={[
                          {
                            required: true,
                            message: 'И-мэйл хаягаа оруулна уу!',
                          },
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="И-мэйл*" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="provinceId"
                        label="Хот/Аймаг"
                        rules={[
                          {
                            required: true,
                            message: 'Хот/Аймаг оруулна уу!',
                          },
                        ]}
                      >
                        <Select
                          className="sb-select sb-shadow"
                          popupClassName="sb-select-dropdown"
                          placeholder="Хот/Аймаг"
                          // showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }}
                          suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                          options={provinceList}
                          onChange={(id) => {
                            setProvinceId(id)
                            fetchDistrictList(id)
                            setDistrictList([])
                            setSubDistrictList([])
                            registerFormRef.setFieldsValue({
                              districtId: null,
                              subdistrictId: null,
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="districtId"
                        label="Дүүрэг/Сум"
                        rules={[
                          {
                            required: true,
                            message: 'Дүүрэг/Сум оруулна уу!',
                          },
                        ]}
                      >
                        <Select
                          className="sb-select sb-shadow"
                          popupClassName="sb-select-dropdown"
                          placeholder="Дүүрэг/Сум"
                          // showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }}
                          suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                          options={districtList}
                          onChange={(id) => {
                            fetchSubDistrictList(id)
                            registerFormRef.setFieldsValue({
                              subdistrictId: null,
                            })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="subdistrictId"
                        label="Хороо/Баг"
                        rules={[
                          {
                            required: true,
                            message: 'Хороо/Баг оруулна уу!',
                          },
                        ]}
                      >
                        <Select
                          className="sb-select sb-shadow"
                          popupClassName="sb-select-dropdown"
                          placeholder="Хороо/Баг"
                          // showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }}
                          suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                          options={subDistrictList}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="fullAddress"
                        label="Байр"
                        rules={[
                          {
                            required: true,
                            message: 'Байрны дугаараа оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Байр" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Switch
                        checked={privacyPolicyAccepted}
                        defaultChecked={true}
                        onChange={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)}
                      />
                      <Button type="link" style={privacyPolicyTextStyle} onClick={() => setPrivacyPolicyModalVisible(true)}>
                        Би үйлчилгээний нөхцлийг зөвшөөрч байна
                      </Button>
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </Row>
              {country === 'US' || country === 'MN' ? (
                <Row gutter={[20, 20]} className="mt-5" justify="center">
                  <Col span={14}>
                    <Button
                      className="btn-sb btn-sb-primary sb-shadow w-100"
                      disabled={!privacyPolicyAccepted}
                      style={{ fontWeight: 700 }}
                      form="web_register"
                      htmlType="submit"
                    >
                      Бүртгүүлэх
                    </Button>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </Form>
          </div>
        </Row>
        {privacyPolicyModalVisible && (
          <KioskInfoModal
            modalVisible={privacyPolicyModalVisible}
            closeModal={() => setPrivacyPolicyModalVisible(false)}
            width={600}
            btnText="Зөвшөөрч байна"
            onClick={() => {
              setPrivacyPolicyModalVisible(false)
              setPrivacyPolicyAccepted(true)
            }}
          >
            <div style={{ maxHeight: 500 }}>
              <div style={{ textAlign: 'left' }}>
                <p>Үйлчилгээ үзүүлэгч</p>
                <p>"SB EXPRESS INC Company</p>
                <p>USA гааль</p>
                <p>Америк улсын гааль</p>
                <p>MGL гааль</p>
                <p>Монгол улсын гааль</p>
              </div>
              <p>
                <b>Нэг. Ерөнхий зүйл</b>
              </p>
              <p>
                Үйлчилгээний нөхцөл нь "SB EXPRESS INC" company/цаашид Үйлчилгээ үзүүлэгч гэнэ/ болон "SBEXPRESS INC" company харилцагч,
                үйлчилүүлэгчидийн/цаашид үйлчилүүлэгч гэнэ/ хоорондох үйлчилгээтэй холбоотой харилцааг зохицуулна. Үйлчилгээний нөхцөл нь үйлчлүүлэгч
                хүлээн зөвшөөрсөнөөр хүчин төгөлдөр болох бөгөөд үйлчилгээний нөхцөлтэй танилцаагүй тохиолдолд гарсан бүх асуудлыг үйлчилгээний
                нөхцлийн хүрээнд шийднэ. Энэхүү үйлчилгээний нөхцөл нь "SB EXPRESS INC LLC " company-аар үйлчлүүлж буй үйлчлүүлэгчдийн бүх төрлийн
                тээвэрлэлтэнд хамааралтай болно. Үйлчилгээ үзүүлэгч тал үйлчлүүлэгчийн хувийн мэдээллийг нууцлах бөгөөд энэ нь Монгол улсын
                "Байгууллагын нууцын тухай" хуулиар зохицуулагдана.
              </p>
              <p>
                <b>Хоёр. Үйлчилгээ үзүүлэгч талын эрх үүрэг</b>
              </p>
              <p>
                Үйлчилгээ үзүүлэгч талын үндсэн үйл ажиллагаа нь онлайн болон шууд худалдан авалт хийхэд тулслах, АНУ-аас Монгол улсын хооронд ачаа
                бараа тээвэрлэх, тээвэр зуучлалын үйлчилгээ бөгөөд тээвэрлэх гэж буй, тээвэрлэсэн бараа бүтээгдэхүүнд хамаарах АНУ-ын болон Монгол
                улсын гаальтай холбоотой асуудалд ямар нэгэн үүрэг хариуцлага хүлээхгүй болно.
              </p>
              <p>
                Үйлчилгээ үзүүлэгч тал нь өөрийн захиалсан бус үйлчлүүлэгчийн хийсэн захиалгын дагуу онлайн дэлгүүр, худалдаалагчаас шуудангаар ирсэн
                болон савлаж багласан байдлаар хүлээж авсан илгээмжийн бараа бүтээгдэхүүний бүрэн бүтэн байдал, тоо ширхэг зэрэгт нарийвчилсан хяналт
                тавихгүй бөгөөд үүнээс үүдэн гарах үр дагаварт хариуцлага хүлээхгүй болно. Үйлчилгээ үзүүлэгч тал нь Үйлчлүүлэгчийн ачаа, бараа
                бүтээгдэхүүнийг тээвэрлэсэнээс хойш заагдсан хугацаанд /Агаарын тээвэр ажлын 5-10 хоног, Далайн тээвэр ажлын 60-90 хоног/ Монгол улсад
                хүргэх үүрэгтэй. (Тээвэрлэлтийн явцад дамжин өнгөрч буй орнуудад тохиолдсон байгалын гамшиг, улс төрийн тогтворгүй байдал, дайн
                байлдаан зэрэгээс үүдэн гарсан хугацааны өөрчлөлт, эрсдэлүүд гарсан тохиолдолд энэхүү үүрэг хариуцлага хамаарахгүй болно.) Үйлчилгээ
                үзүүлэгч нь Үйлчлүүлэгчийг ачаа барааны байршлын мэдээлэл түүнтэй холбоотой бусад мэдээллээр хангах үүрэгтэй.
              </p>
              <p>
                <b>Гурав. Үйлчлүүлэгч талын эрх үүрэг</b>
              </p>
              <p>
                Үйлчлүүлэгч нь АНУ-ын онлайн дэлгүүрүүдээс худалдан авалтаа Үйлчилгээ үзүүлэгчийн вэйб хуудсаар дамжуулан захиалга хийлгэсэн
                тохиолдолд худалдан авалт хийх бараа бүтээгдхүүний мэдээллийг Үйлчилгээ үзүүлэгчийн вэйб хуудсаар өгсөн шаардлагын дагуу барааны өнгө,
                размер, тоо ширхэгийг бичих, өөрийн холбогдох мэдээлийг үнэн зөв бүртгүүлэх үүрэгтэй. Үйлчлүүлэгч нь АНУ-ын онлайн дэлгүүрүүдээс
                худалдан авалтаа өөрөө шууд хийсэн тохиолдолд Үйлчилгээ үзүүлэгчийн вэйб хуудас дээр байгаа зөвлөмжийн дагуу хүлээн авах хаягаа бичиж
                Монголд хүлээн авах үүрэгтэй. Үйлчлүүлэгч нь илгээж буй ачаа, бараа бүтээгдхүүнээ АНУ-д байрлах Үйлчилгээ үзүүлэгчийн ажлын байранд
                авчирж өгсөн тохиолдолд бараа бүтээгдэхүүний талаархи мэдээллийг үнэн зөв мэдүүлэх үүрэгтэй бөгөөд буруу өгсөн мэдээллээс үүдэн гарах
                хариуцлагыг Үйлчлүүлэгч бүрэн хариуцах үүрэгтэй. Үйлчлүүлэгч нь Үйлчилгээ үзүүлэгчээр бараа захиалга хийлгэх бол бараа захиалгын
                төлбөрийг 100% урьдчилан төлөх бөгөөд худалдан авалтын төлбөрийг АНУ-руу шилжүүлэхэд шаардагдах мөнгөн гуйвуулгын хураамжыг нэмж
                төлнө. Мөнгөн гуйвуулгын хураамж нь дараахи байдлаар тооцогдоно. $1-$250 =$5, $251-$500=$10 буюу $250 тутамд $5 нэмэгдэх болно.
                Үйлчлүүлэгч тал нь тээвэрлэхийг хориглосноос бусад бүх төрлийн бараа бүтээгдэхүүнийг АНУ-аас Монгол улсруу тээвэрлүүлэх эрхтэй.
                Үйлчлүүлэгч тал нь ачаа, барааныхаа мэдээллийг Үйлчилгээ үзүүлэгч талын Америк дахь хаягт хүргэгдсэн цагаас хойш Үйлчилүүлэгчийн вэйб
                хуудасаар дамжуулан авах эрхтэй. Үйлчилгээ үзүүлэгч тал нь Үйлчлүүлэгчид ачаа Монголд ирсэн талаар утсаар мэдэгдэл өгсөнөөс 7 хоногийн
                дотор ачаагаа авах үүрэгтэй.
              </p>
              <p>
                <b>Дөрөв. Ачааны төлбөр тооцоо</b>
              </p>
              <p>
                "SB EXPRESS INC" company агаарын ачааны тээврийн үнэ нь 1кг=$10 бөгөөд төлбөрийг Монгол дахь оффисд хийсэн тохиолдолд тухайн өдрийн
                Монгол банкны валютын зарах ханшаар бодогдоно. 1кг-аас бага жинтэй ачааг тээвэрлэхдээ дараахи байдлаар тооцно. 1-1000гр=$10
                Үйлчлүүлэгч тал АНУ-д байрлах Үйлчилгээ үзүүлэгчийн ажлын байранд авчирж өгсөн ачааны тээврийн төлбөрийг бэлнээр болон Монголд ачаагаа
                хүлээж авахдаа төлөгдөх нөхцөлтэйгөөр хийж болох бөгөөд ачаагаа авахадаа төлбөрийг 100% төлж барагдуулна. Үйлчлүүлэгч онлайн
                үйлчилгээгээр үйлчилүүлэн ирсэн ачаагаа авах тохиолдолд тээврийн төлбөрийг 100% төлнө. Агаарын ачааны тээврийн хувьд олон улсын
                агаарын тээврийн үнэлгээний журмын дагуу тухайн ачааны бодит жин болон оворын хэмжээг харьцуулан үзээд аль илүү гарсанаар тээврийн
                төлбөр бодогдоно. Жишээ нь: Гитар , хайрцагтай бодит жин 6кг оворын хэмжээ 44inch x 17inch x 7inch=5236:366=14.3кг Далайн тээвэр -
                Бизнесийн бус ахуйн хэрэгцээний бараа бүтээгдэхүүний хувьд дараахь байдлаар тээврийн үнэлгээг бодож гаргана. Жишээ нь: Ахуйн
                хэрэгцээний хувцас, чихэр, бусад ижил төрлийн бус холимог ачаа байсан тохиолдолд Бизнесийн зориулалттай, нэг төрлийн болон их
                хэмжээний ачааны үнэлгээг Үйлчилгээ үзүүлэгчийн 70005088. 75955088 гэсэн дугаараар холбогдон далайн тээвэр хариуцсан ажилтанаас авах
                боломжтой.
              </p>
              <p>
                <b>Тав. Гаалийн бүрдүүлэлтийн тухай</b>
              </p>
              <p>
                Үйлчлүүлэгчийн тээвэрлүүлсэн ачаа, бараа бүтээгдэхүүнд Монгол улсын гааль бүрдүүлэлтийн журмын дагуу зарим бараа бүтээгдэхүүнийг
                саатуулан татвар ноогдуулах эрхтэй бөгөөд ийм тохиолдолд хүлээн авагч татварыг бүрэн хариуцана. Үйлчлүүлэгч өөрийн биеэр Монгол улсын
                гааль дээр очиж гаалын бүрдүүлэлт хийн, ноогдсон татварыг төлж бараагаа хүлээж авна. Үйлчилгээ үзүүлэгчийн тээврийн төлбөрийг 100%
                төлж төлбөрийн тооцоогүй гэсэн баримтыг авч гаалын бүрдүүлэлтээ хийж авна. Монгол улсын гаальд татвар бодуулахдаа барааны үнийн дүн +
                тээврийн зардлын нийлбэр дүнгээс 15,5% татвар төлөх үүрэгтэй.
              </p>
              <p>
                <b>Зургаа. Ачааны эвдрэл гэмтэл болон Даатгал</b>
              </p>
              <p>
                Үйлчлүүлэгч тал нь бараа бүтээгдэхүүнээ ямар нэгэн эрсэдлээс хамгаалж даатгал хийлгэж болох бөгөөд энэ нь тухайн барааны үнийн дүнгийн
                30%-тай тэнцэх төлбөр байна. Үйлчилгээ үзүүлэгч тал нь зөвхөн тээвэр зуучийн компани бөгөөд даатгагдаагүй барааны эвдрэл гэмтэл
                зэргийг хариуцахгүй болно. Онлайн дэлгүүрээс Үйлчилгээ үзүүлэгч талын АНУ-д байрлах ажлын байранд хаягаар хүргэгдэн ирсэн бараа
                бүтээгдэхүүний эвдрэл, гэмтэл, тоо ширхэгийн бүрэн бүтэн байдлыг дэлгүүр болон бараа худалдаалагч, онлайн дэлгүүр хариуцна.
                Үйлчлүүлэгч нь 7.1 заасны дагуу даатгал хийлгэсэн тохиолдолд гэмтсэн бараа бүтээгдэхүүний нөхөн олговорыг Үйлчилгээ үзүүлэгч талаас
                нэхэмжилж 100% гаргуулах эрхтэй.
              </p>
              <p>
                <b>Долоо. Асуудал, маргаан шийдвэрлэх</b>
              </p>
              <p>
                Үйлчилгээ үзүүлэгч нь Үйлчлүүлэгчийн санал гомдлыг 70005088, 75955088 утсуудаар мөн mgl_zam_usa@yahoo.com имэйл хаягаар хүлээн авч
                шийдвэрлэнэ. Тээвэрлэлтийн явцад дамжин өнгөрч буй орнуудад тохиолдсон байгалын гамшиг, улс төрийн тогтворгүй байдал, дайн байлдаан,
                Монгол Улсын эрх бүхий төрийн байгууллагаас гаргасан тогтоол, хориг, захирамж зэрэгээс үүдэн гарсан тээврийн үйл ажиллагааны тасалдал,
                хугацааны өөрчлөлт, эрсдэлүүд гарсан тохиолдолд Үйлчилгээ үзүүлэгч хариуцлага хүлээх үндэслэл болохгүй. Үйлчилгээ үзүүлэгч тал өөрийн
                эрх үүргээ зөрчсөн, үйлчлүүлэгч талд хүндрэл учруулсан бол хөнгөлөлт урамшуулал үзүүлж маргааныг шийдвэрлэж болно. Үйлчлүүлэгч нь
                үйлчилгээний нөхцлийг зөрчсөн тохиолдолд Үйлчилгээ үзүүлэгч тал нь үйлчлүүлэгчийн эрхийг цуцлах, хоошид үйлчилгээ үзүүлэхгүй байх
                эрхтэй. Үйлчилгээтэй холбоотой ямарваа асуудлыг талууд тохиролцоонд хүрэх замаар шийдвэрлэх ба тохиролцоонд эс хүрвэл талууд холбогдох
                хууль тогтоомжийн дагуу шийдвэрлүүлнэ.
              </p>
            </div>
          </KioskInfoModal>
        )}
      </div>
    </Spin>
  )
}

export default RegisterPage
